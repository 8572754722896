<template>
  <div>
    <a-modal
      title="修改存储空间"
      :visible="visible"
      width="24%"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="handleCancel"
    >
      <div class="content">
        <div class="tips text-lightblue flex flex-column m-b-sm">
          <span>当前剩余可分配空间：{{ totalSize }}；</span>
          <template>
            <span v-show="mode === 'multiple'"
              >批量最低分配空间：{{ usedSpaceMinSize }}，<br />最高可分配{{
                averageSpaceSize
              }}</span
            >
            <span v-show="mode === 'single'"
              >当前用户最低分配空间：{{ usedSpaceMinSize }}</span
            >
          </template>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="title">存储配置：</span>
          <span class="flex-one">
            <a-input-number
              v-model="params.size"
              :min="min"
              :max="max"
              :precision="2"
              :step="0.1"
              class="m-r-xs"
            />
            <a-select v-model="params.type" style="width: 80px">
              <a-select-option
                v-for="item in selectType"
                :value="item.value"
                :key="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </span>
        </div>

        <div class="flex m-t-md items-center justify-center">
          <a-button
            type="primary"
            class="btn-primary m-r-sm"
            @click="sureHandle"
            >确定</a-button
          >
          <a-button class="btn-reset" @click="handleCancel">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'SingleDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'single',
    },
    currentRecord: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      params: {
        size: 0,
        type: 'MB',
      },
      selectType: [
        {
          value: 'MB',
          text: 'MB',
        },
        {
          value: 'KB',
          text: 'KB',
        },
        {
          value: 'GB',
          text: 'GB',
        },
      ],
      totalSize: '--',
      averageSpaceSize: '--',
      usedSpaceMinSize: '--',
      surplusSpaceSize: '--',
      min: 0.0,
      max: 0.0,
    };
  },
  computed: {},
  watch: {
    visible(nv, ov) {
      if (nv && nv !== ov) {
        this._getSpaceInfo();
      }
    },
    'params.type'() {
      this.getMin();
      this.getMax();
      this.params.size = this.min;
    },
  },
  methods: {
    getMin() {
      let type = this.params.type;
      let actualType = this.isType(this.usedSpaceMinSize);
      let actualValue = this.usedSpaceMinSize.replace(actualType, '');
      switch (type) {
        case 'KB':
          switch (actualType) {
            case 'KB':
              this.min = Number(actualValue);
              break;
            case 'MB':
              this.min = Number(
                parseFloat(Number(actualValue) * 1024).toFixed(2)
              );
              break;
            case 'GB':
              this.min = Number(
                parseFloat(Number(actualValue) * 1024 * 1024).toFixed(2)
              );
              break;
          }
          break;
        case 'MB':
          switch (actualType) {
            case 'KB':
              this.min = Number(
                parseFloat(Number(actualValue) / 1024).toFixed(2)
              );
              break;
            case 'MB':
              this.min = Number(actualValue);
              break;
            case 'GB':
              this.min = Number(
                parseFloat(Number(actualValue) * 1024).toFixed(2)
              );
              break;
          }
          break;
        case 'GB':
          switch (actualType) {
            case 'KB':
              this.min = Number(
                parseFloat(Number(actualValue) / 1024 / 1024).toFixed(2)
              );
              break;
            case 'MB':
              this.min = Number(
                parseFloat(Number(actualValue) / 1024).toFixed(2)
              );
              break;
            case 'GB':
              this.min = Number(actualValue);
              break;
          }
          break;
      }
    },
    isType(value) {
      return String(value).slice(-2);
    },
    // eslint-disable-next-line no-unused-vars
    getMax() {
      let type = this.params.type;
      let actualType = this.isType(this.totalSize);
      let actualValue = this.totalSize.replace(actualType, '');
      switch (type) {
        case 'KB':
          switch (actualType) {
            case 'KB':
              this.max = Number(actualValue);
              break;
            case 'MB':
              this.max = Number(
                parseFloat(Number(actualValue) * 1024).toFixed(2)
              );
              break;
            case 'GB':
              this.max = Number(
                parseFloat(Number(actualValue) * 1024 * 1024).toFixed(2)
              );
              break;
          }
          break;
        case 'MB':
          switch (actualType) {
            case 'KB':
              this.max = Number(
                parseFloat(Number(actualValue) / 1024).toFixed(2)
              );
              break;
            case 'MB':
              this.max = Number(actualValue);
              break;
            case 'GB':
              this.max = Number(
                parseFloat(Number(actualValue) * 1024).toFixed(2)
              );
              break;
          }
          break;
        case 'GB':
          switch (actualType) {
            case 'KB':
              this.max = Number(
                parseFloat(Number(actualValue) / 1024 / 1024).toFixed(2)
              );
              break;
            case 'MB':
              this.max = Number(
                parseFloat(Number(actualValue) / 1024).toFixed(2)
              );
              break;
            case 'GB':
              this.max = Number(actualValue);
              break;
          }
          break;
      }
    },
    _getSpaceInfo() {
      this.$api.storeManage.getSpaceInfo(this.currentRecord).then((res) => {
        if (res.code === CODE_OK) {
          this.totalSize = res.data.adminSize;
          this.usedSpaceMinSize = res.data.usedSpaceMinSize;
          this.surplusSpaceSize = res.data.surplusSpaceSize;
          this.averageSpaceSize = res.data.averageSpaceSize;
          this.getMin();
          this.getMax();
          this.params.size = this.min;
        }
      });
    },
    handleCancel() {
      this.resetHandle();
      this.$emit('close');
    },
    sureHandle() {
      let storageSpaceNumber = this.params.size + this.params.type;
      const params = this.currentRecord.map((item) => {
        return {
          id: item.id,
          storageSpaceNumber,
        };
      });
      this.$api.storeManage.modifyMemory(params).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success('设置存储成功');
          this.$emit('refreshData');
          this.resetHandle();
        }
      });
    },
    resetHandle() {
      this.params = {
        size: 0,
        type: 'MB',
      };
    },
  },
};
</script>

<style scoped>
.title {
  flex: 0 0 70px;
}
.equal {
  text-align: center;
  border: 1px solid #4c658a;
  line-height: 32px;
  flex: 0 0 40px;
  border-radius: 3px;
}
</style>
