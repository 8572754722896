<template>
  <div class="full-width full-height">
    <tab :tabs="tabs" :initTab="initTab" @select="selectHandle" />
    <div class="m-t">
      <div class="m-l-md">
        <div class="flex justify-between items-center m-b-sm text-lightblue">
          <div class="flex items-center">
            <span class="flex-s m-r-xs">管辖单位：</span>
            <a-cascader
              :options="organizationListFormat"
              :display-render="displayRender"
              v-model="searchParams.organizationId"
              expand-trigger="hover"
              placeholder="管辖单位"
              change-on-select
              class="m-r-sm search-mx-wd-1"
              @change="selectOrganization"
            />
            <span class="flex-s m-r-xs">用户名：</span>
            <a-input
              v-model="searchParams.name"
              placeholder="请输入用户姓名"
              class="m-r-sm  search-mx-wd-1"
            ></a-input>
            <span class="flex-s m-r-xs">手机号：</span>
            <a-input
              v-model="searchParams.phone"
              placeholder="请输入手机号"
              class="m-r-sm search-mx-wd-1"
            ></a-input>
            <a-button
              type="primary"
              class="m-r-sm btn-search"
              @click="searchHandle"
            >
              搜索
            </a-button>
            <a-button class="btn-reset m-r-sm" @click="resetHandle">
              重置
            </a-button>
          </div>
          <div class="flex">
            <span class="span-line m-l-md m-r-md"></span>
            <a-button class="btn-export pointer" @click="detachModify">
              批量修改
            </a-button>
          </div>
        </div>
        <div class="text-right text-lightblue">
          <span class="m-r-sm">存储空间共 {{ totalSize }}</span>
          <span class="m-r-sm">剩余 {{ surplusSpaceSize }}</span>
          <span>已使用 {{ userSpaceSize }}</span>
        </div>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :rowKey="(row) => row.id"
          :columns="columns"
          :components="resibleTableHeader"
          :data-source="data"
          :pagination="paginationOpt"
          :scroll="scroll"
          :loading="loading"
          bordered
        >
          <template slot="customOpe" slot-scope="text, record">
            <a-tooltip placement="top">
              <template slot="title">
                <span>编辑</span>
              </template>
              <a-icon
                v-show="record['userName'] != '超级管理员'"
                type="edit"
                class="m-r-sm pointer"
                @click="editHandle(record)"
              />
            </a-tooltip>
          </template>
        </a-table>
      </div>
    </div>
    <modify-dialog
      :visible="visibleModify"
      :mode="modeType"
      :currentRecord="currentRecord"
      @close="closeHandle"
      @refreshData="refreshDataHandle"
    />
  </div>
</template>

<script>
import Tab from '@/components/Tab';
import tabMixin from '@/components/mixins/tabs';
import ModifyDialog from './ModifyDialog';
import { CODE_OK } from '@/request/config_code';
import { mapState, mapActions } from 'vuex';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'StoreManage',
  mixins: [tabMixin,ResizableTable],
  data() {
    return {
      loading: false,
      scroll: { y: window.innerHeight - 370,x:'100%' },
      searchParams: {
        name: '',
        phone: '',
        organizationId: [],
      },
      organizationCurrentId: '',
      columns: [
        {
          title: '序号',
          dataIndex:'storeIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
          ellipsis: true,
        },
        {
          title: '管辖单位',
          dataIndex: 'organizationName',
          width:200
        },
        {
          title: '用户名',
          dataIndex: 'loginName',
          width: 100,
          ellipsis: true,
        },
        {
          title: '真实姓名',
          dataIndex: 'userName',
          width: 100,
          ellipsis: true,
        },
        {
          title: '联系方式',
          dataIndex: 'phoneNumber',
          width: 100,
          ellipsis: true,
        },
        {
          title: '存储空间',
          dataIndex: 'storageSpaceNumber',
          width: 100,
          ellipsis: true,
        },
        {
          title: '已用存储',
          dataIndex: 'usedSpaceNumber',
          width: 100,
          ellipsis: true,
        },
        {
          title: '剩余存储',
          dataIndex: 'surplusSpaceNumber',
          width: 100,
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'ope',
          className:'column-center',
          scopedSlots: {
            customRender: 'customOpe',
          },
          width: 60,
        },
      ],
      data: [],
      selectedRowKeys: [],
      visibleModify: false,
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 4, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getStore();
          this.resetSelectRow();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getStore();
          this.resetSelectRow();
        },
      },
      totalSize: '--',
      userSpaceSize: '--',
      surplusSpaceSize: '--',
      modeType: '',
      currentRecord: [],
    };
  },
  computed: {
    ...mapState({
      organizationList: (state) => state.collect.organizationList,
      userInfo: (state) => state.login.userInfo,
    }),
    organizationListFormat() {
      return this.organizationList.map((item) => {
        return item;
      });
    },
  },
  watch: {},
  created() {
    this.getOrganationList();
    this._getStore();
    this._getSpaceInfo([]);
  },
  methods: {
    ...mapActions(['getOrganationList']),
    getHeight() {
      let winHeight = window.innerHeight;
      return { height: `${winHeight - 240}px` };
    },
    selectHandle(index) {
      this.selectCurrent(index);
    },
    _getStore() {
      const params = {
        currPage: this.paginationOpt.current,
        loginName: this.searchParams.name,
        organizationId: this.organizationCurrentId,
        pageSize: this.paginationOpt.defaultPageSize,
        phoneNumber: this.searchParams.phone,
      };
      this.loading = true;
      this.$api.storeManage.getList(params).then((res) => {
        if (res.code === CODE_OK) {
          this.loading = false;
          this.data = res.data;
          this.paginationOpt.total = res.total;
          this.$nextTick(() => {
            this.getHeight();
          });
        }
      });
    },
    _getSpaceInfo(params) {
      this.$api.storeManage.getSpaceInfo(params).then((res) => {
        if (res.code === CODE_OK) {
          this.totalSize = res.data.totalSize;
          this.userSpaceSize = res.data.userSpaceSize;
          this.surplusSpaceSize = res.data.surplusSpaceSize;
        }
      });
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getStore();
    },
    resetHandle() {
      this.searchParams.name = '';
      this.searchParams.phone = this.organizationCurrentId = '';
      this.searchParams.organizationId = [];
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getStore();
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    resetSelectRow() {
      this.selectedRowKeys = [];
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectOrganization(value) {
      this.searchParams.organizationId = value;
      this.organizationCurrentId =
        this.searchParams.organizationId.length > 0
          ? this.searchParams.organizationId[
              this.searchParams.organizationId.length - 1
            ]
          : '';
    },
    closeHandle() {
      this.visibleModify = false;
      this.currentRecord = [];
      this.resetSelectRow();
    },
    refreshDataHandle() {
      this.closeHandle();
      this._getStore();
    },
    editHandle(record) {
      this.modeType = 'single';
      this.visibleModify = true;
      this.currentRecord = [{ id: record.id }];
    },
    detachModify() {
      this.modeType = 'multiple';
      this.currentRecord = this.selectedRowKeys.map((item) => ({ id: item }));
      if (this.currentRecord.length <= 0) {
        this.$message.error('请选择存储记录');
        return;
      }
      this.visibleModify = true;
    },
  },
  components: {
    Tab,
    ModifyDialog,
  },
};
</script>

<style scoped>
.left {
  flex: 0 0 200px;
  width: 200px;
}
.w20 {
  width: 60%;
}
</style>
